<template>
  <div class="remote-input-select">
    <CInput
      v-model="input"
      :placeholder="computedPlaceholder"
      :disabled="disabled"
      :class="{ 'value-text': selected && (selected.label || selected.length) }"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="fetchOptions"
    />
    <span class="caret"><CIcon name="md-expand-more" height="15" /></span>
    <div class="dropdown-menu mt-1" :class="{ show: showDropdown }">
      <div v-if="loading" class="dropdown-menu__message">
        Loading
      </div>
      <div v-else-if="!options.length" class="dropdown-menu__message">
        No data
      </div>
      <template v-if="multiple">
        <div
          v-for="option in options"
          :key="option[valueKey]"
          class="dropdown-item"
          tabindex="0"
          @blur="handleBlur"
        >
          <CInputCheckbox
            :label="option.label"
            :value="option[valueKey]"
            :checked="selected.some(e => e[valueKey] === option[valueKey])"
            :custom="true"
            @update:checked="checked => handleCheck(checked, option)"
            @blur="handleBlur"
          />
        </div>
      </template>
      <template v-else>
        <CDropdownItem
          v-for="option in options"
          :key="option[valueKey]"
          :value="option[valueKey]"
          @click="handleSelect(option)"
        >
          {{ option.label }}
        </CDropdownItem>
      </template>
    </div>
  </div>
</template>

<script>
/**
 * Custom remote input select component that supports single and multiple selections
 * Loosely based on https://element.eleme.io/#/en-US/component/select
 *
 * Example:
 * <RRemoteInputSelect
 *   v-model="selectedProperties"
 *   :remote-method="fetchPropertyOptions"
 *   multiple
 * />
 *
 * selectedProperties: [{ label: '01 Test Property', value: 14396 }]
 *
 * async fetchPropertyOptions (query) {
 *   const params = new URLSearchParams({ q: query })
 *   const response = await RooofAPI.companies.propertySummary({ id: this.$route.params.cid, params })
 *   return response.data.map(property => {
 *     return { label: property.name, value: property.id }
 *   })
 * }
 *
 */
import debounce from 'lodash.debounce'

export default {
  name: 'RRemoteInputSelect',
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Search'
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    selected: {
      type: undefined,
      required: true
    },
    remoteMethod: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      showDropdown: false,
      loading: false,
      input: '',
      options: []
    }
  },
  computed: {
    computedPlaceholder () {
      if (this.multiple && this.selected.length) {
        return this.selected.length + ' item(s) selected'
      }
      if (this.selected && this.selected.label) {
        return this.selected.label
      }
      return this.placeholder
    }
  },
  created () {
    if (this.multiple) {
      if (this.selected.length) {
        this.options = this.selected
      }
    } else {
      if (this.selected) {
        this.options.push(this.selected)
      }
    }
  },
  methods: {
    /**
     * Handler for when the input is focused
     */
    handleFocus () {
      this.input = ''
      if (this.options.length) {
        this.showDropdown = true
      }
    },
    /**
     * Handler for when the input is blurred
     *
     * @param {Object} event - blur event
     */
    handleBlur (event) {
      this.input = ''
      if (!event.relatedTarget ||
      (!event.relatedTarget.classList.contains('dropdown-item') &&
      !event.relatedTarget.classList.contains('custom-control-input'))) {
        this.showDropdown = false
      }
    },
    /**
     * Fetches the select options given an async function that calls the API.
     * We use a 400 millisecond debounce here so we don't call the API on every user input.
     *
     * @param {String} query
     */
    fetchOptions: debounce(async function (query) {
      if (!query) return
      this.loading = true
      this.showDropdown = true
      this.options = await this.remoteMethod(query)
      this.loading = false
    }, 400),
    /**
     * Handler for when an option is selected (single select)
     *
     * @param {Object} option - e.g. { label: 'Option 1', value: 123 }
     */
    handleSelect (option) {
      this.$emit('change', option)
      this.input = ''
      this.showDropdown = false
    },
    /**
     * Handler for checking/unchecking an option (multi select)
     *
     * @param {Boolean} checked
     * @param {Object} option - e.g. { label: 'Option 1', value: 123 }
     */
    handleCheck (checked, option) {
      const newSelected = [...this.selected]
      if (checked) {
        newSelected.push(option)
      } else {
        newSelected.splice(this.selected.findIndex(e => e[this.valueKey] === option[this.valueKey]), 1)
      }
      this.$emit('change', newSelected)
    }
  }
}
</script>

<style lang="scss" scoped>
.remote-input-select {
  position: relative;

  ::v-deep .value-text > input::placeholder {
    color: $gray-900;
  }

  .caret {
    position: absolute;
    top: 0;
    right: 0;
    height: 35px;
    padding: 6px 10px;
  }

  .dropdown-menu {
    width: 100%;
    .dropdown-menu__message {
      text-align: center;
    }
  }
}
</style>
