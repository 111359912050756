<template>
  <div class="r-summary-table">
    <CDataTable
      v-bind="tableProps"
      :items="tableData"
      :fields="fields"
    >
      <!-- render table columns -->
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </CDataTable>
  </div>
</template>

<script>
/**
 * RSummaryTable
 *
 * Wrapper for CoreUI DataTable with minimal styling.
 */
import merge from 'lodash.merge'

export default {
  name: 'RSummaryTable',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    tableProps () {
      const defaults = {
        columnFilter: false,
        tableFilter: false
      }
      return merge(defaults, this.options)
    },
    tableData () {
      // Replace all falsy values with '-'
      return this.items.map(row => {
        for (const col in row) {
          if (!row[col]) {
            row[col] = '-'
          }
        }
        return row
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep thead th {
  border: none;
  &:not(:first-child) {
    text-align: center;
  }
}
::v-deep tbody td {
  border-top: 1px solid #fff;
  &:not(:first-child) {
    text-align: center;
  }
}
::v-deep .table {
  margin-bottom: 0;
}
</style>
