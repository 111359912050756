<template>
  <div class="input-filter btn btn-pill" :class="{ 'btn-info' : isFilled }">
    <CInput
      :value="value"
      v-bind="$attrs"
      :readonly="isFilled"
      :placeholder="!isFocus ? placeholder : ''"
      @change="handleInputChange"
      @focus="handleInputFocus"
      @blur="isFocus = false"
    >
      <template #append-content>
        <div :class="isFocus ? 'invisible' : 'visible'" @click="handleIconClick">
          <CIcon
            :name="isFilled ? 'md-cancel' : 'md-search'"
            :class="{ clickable: isFilled }"
            height="15"
          />
        </div>
      </template>
    </CInput>
  </div>
</template>

<script>

/**
 * Wrapper component for CInput which is styled like a pill button
 * and becomes read-only once input is entered.
 *
 * Events:
 *
 * @clear: triggered when content is cleared
 * @change: triggered when content is changed
 */
export default {
  name: 'RInputFilter',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isFocus: false
    }
  },
  computed: {
    isFilled () {
      return !this.isFocus && Boolean(this.value)
    }
  },
  methods: {
    /**
     * onFocus event handler for focusing input field.
     */
    handleInputFocus () {
      if (!this.isFilled) {
        this.isFocus = true
      }
    },
    /**
     * onChange event handler for entering input.
     */
    handleInputChange (value) {
      this.isFocus = false
      this.$emit('change', value)
    },
    /**
     * onClick event handler for clicking on the appended icon.
     */
    handleIconClick () {
      if (this.isFilled) {
        this.$emit('change', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-filter {
  cursor: initial !important;

  &:hover {
    cursor: initial;
  }
  &:not(.btn-info):hover {
    background-color: #e9ebf180;
    color: $gray-900;
  }

  .form-group {
    display: inline-block;
    margin: 0;

    ::v-deep input {
      cursor: initial;
      color: inherit;
      padding: 0;
      height: 100%;
      border: none;
      background-color: transparent !important;
        &:focus {
          box-shadow: none;
          color: inherit;
        }
        &::placeholder {
          color: inherit;
        }
    }

    ::v-deep .input-group-append {
      .input-group-text {
        border: none;
        padding: 0;
        background-color: inherit;
        color: inherit;
        .clickable {
          cursor: pointer;
        }
        div {
          display: inherit;
        }
      }
    }
  }
}
</style>
