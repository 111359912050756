<template>
  <div class="dropdown-multiselect">
    <CDropdown
      :add-toggler-classes="{ 'btn-pill': true, 'btn-info': selected.length }"
      :offset="[0, 15]"
      :caret="false"
      v-bind="$attrs"
    >
      <template #toggler-content>
        <slot />
        <CIcon :class="{ white: selected.length }" name="md-expand-more" height="15" />
      </template>
      <div v-for="option in options" :key="option.value" class="dropdown-item">
        <CInputCheckbox
          :label="option.label"
          :value="option.value"
          :checked="selected.includes(option.value)"
          :custom="true"
          @update:checked="checked => handleCheck(checked, option.value)"
        />
      </div>
    </CDropdown>
  </div>
</template>

<script>
/**
 * Wrapper component for CDropdown which uses CInputCheckbox for
 * the dropdown menu items.
 *
 * Events:
 *
 * @change: triggered when the list of selected options has changed
 */
export default {
  name: 'RDropdownMultiSelect',
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
     * handler for checking/unchecking an option in the dropdown menu
     */
    handleCheck (checked, item) {
      const newSelected = [...this.selected]
      if (checked) {
        newSelected.push(item)
      } else {
        newSelected.splice(this.selected.indexOf(item), 1)
      }
      this.$emit('change', newSelected)
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-multiselect {
  ::v-deep .btn-pill {
    &:hover:not(.btn-info) {
      background-color: #e9ebf180;
      color: $gray-900;
    }
  }
  .dropdown-menu {
    .dropdown-item {
      padding: 0.25rem 1rem;
      &:hover {
        opacity: 0.7;
      }
      &:hover, &:active {
        color: inherit;
        background-color: inherit;
      }
      ::v-deep label {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .white {
    color: $white;
  }
}
</style>
