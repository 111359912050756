<template>
  <CRow>
    <CCol>
      <h6 v-if="label">
        {{ label }}
      </h6>
      <CRow>
        <CCol>
          <CInput
            label="Attention To"
            :value="attention_to"
            @update:value="$emit('update:attention_to', $event)"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CInput
            label="Street"
            :value="street"
            :is-valid="validateField('street')"
            @update:value="setValue('street', $event)"
          >
            <template #invalid-feedback>
              <div v-if="!$v.street.required" class="invalid-feedback">
                This field is required
              </div>
              <div v-if="!$v.street.maxLength" class="invalid-feedback">
                This field must not exceed 300 characters
              </div>
            </template>
          </CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CInput
            label="City"
            :value="city"
            :is-valid="validateField('city')"
            @update:value="setValue('city', $event)"
          >
            <template #invalid-feedback>
              <div v-if="!$v.city.required" class="invalid-feedback">
                This field is required
              </div>
              <div v-if="!$v.city.maxLength" class="invalid-feedback">
                This field must not exceed 100 characters
              </div>
            </template>
          </CInput>
        </CCol>
        <CCol>
          <CSelect
            label="State"
            invalid-feedback="This field is required"
            :value="state"
            :options="country === 'Canada' ? provinces : states"
            :is-valid="validateField('state')"
            @update:value="setValue('state', $event)"
          />
        </CCol>
        <CCol>
          <CInput
            label="Postal"
            :value="postal"
            :is-valid="validateField('postal')"
            @update:value="setValue('postal', $event)"
          >
            <template #invalid-feedback>
              <div v-if="!$v.postal.required" class="invalid-feedback">
                This field is required
              </div>
              <div v-if="!$v.postal.alphaNum" class="invalid-feedback">
                Please enter a valid postal code
              </div>
              <div v-if="!$v.postal.maxLength" class="invalid-feedback">
                This field must not exceed 10 characters
              </div>
            </template>
          </CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CInput
            label="Country"
            :value="country"
            :is-valid="validateField('country')"
            @update:value="setValue('country', $event)"
          >
            <template #invalid-feedback>
              <div v-if="!$v.country.required" class="invalid-feedback">
                This field is required
              </div>
              <div v-if="!$v.country.maxLength" class="invalid-feedback">
                This field must not exceed 100 characters
              </div>
            </template>
          </CInput>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
/**
 * RInputAddress
 *
 * Displays address form fields.
 *
 * Usage:
 *
 * <RInputAddress v-bind.sync="addressObject" />
 *
 * Events:
 *
 * @update:[value]: emitted on `value` change
 */
import { validationMixin } from 'vuelidate'
import { alphaNum, maxLength, required } from 'vuelidate/lib/validators'

import constants from '@/utils/constants'

export default {
  name: 'RInputAddress',
  mixins: [validationMixin],
  validations: {
    street: { required, maxLength: maxLength(300) },
    city: { required, maxLength: maxLength(100) },
    state: { required, maxLength: maxLength(2) },
    postal: { required, alphaNum, maxLength: maxLength(10) },
    country: { required, maxLength: maxLength(100) }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    /* eslint-disable vue/prop-name-casing */
    attention_to: {
      type: String,
      default: ''
    },
    street: {
      type: String,
      default: ''
    },
    city: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default: ''
    },
    postal: {
      type: String,
      default: ''
    },
    country: {
      type: String,
      default: ''
    }
  },
  computed: {
    isValid () {
      return !this.$v.$invalid
    }
  },
  created () {
    this.states = [
      { label: '-', value: null },
      ...constants.address.State.getAsArray({ flat: true, inverted: true })
    ]
    this.provinces = [
      { label: '-', value: null },
      ...constants.address.Province.getAsArray({ flat: true, inverted: true })
    ]
  },
  methods: {
    /**
     * Set a form field, and mark the field as dirty.
     *
     * @param {String} fieldName
     * @param {Object} event
     */
    setValue (fieldName, event) {
      this.$v[fieldName].$touch()
      this.$emit(`update:${fieldName}`, event)
    },
    /**
     * Validate a form field.
     *
     * @param {String} fieldName
     * @returns {Boolean}
     */
    validateField (fieldName) {
      const field = this.$v[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !field.$invalid
    },
    /**
     * Validate the form, triggering bootstrap validation.
     *
     * Called by parent using refs.
     *
     * @returns {Boolean}
     */
    validate () {
      this.$v.$touch()
      return this.isValid
    },
    /**
     * Reset the validation, removing bootstrap validation.
     *
     * Called by parent using refs.
     */
    reset () {
      this.$v.$reset()
    }
  }
}
</script>
