<template>
  <div class="paginated-table">
    <CRow>
      <CCol>
        <RDataTable
          :items="data"
          :fields="columns"
          :table-props="pagedTableProps"
          @pagination-change="handlePageSizeChange"
        >
          <!-- render table columns -->
          <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
          </template>
        </RDataTable>
      </CCol>
    </CRow>
    <CRow align-vertical="baseline">
      <CCol md="auto" sm="6">
        <CPagination
          :dots="false"
          :pages="totalPages"
          :active-page="page"
          @update:activePage="handlePageChange"
        />
      </CCol>
      <CCol sm="6">
        {{ startIndex }}-{{ endIndex }} of {{ totalRecords }}
      </CCol>
    </CRow>
  </div>
</template>

<script>
/**
 * Wrapper component for supporting pagination on RDataTable.
 *
 * Events:
 *
 * @fetch: triggered when component state changes occur that require (re-)populating the data.
 * @page-change: triggered when the selected page or pageSize are changed.
 */
import merge from 'lodash.merge'

import RDataTable from '@/components/tables/RDataTable'

/**
 * Wrapper for CoreUI CDataTable with async pagination
 * and (optional) page select controls.
 *
 * The parent component should handle the `fetch` event and set
 * the `data` and `totalRecords` props accordingly.
 */
export default {
  name: 'RAsyncPagedTable',
  components: {
    RDataTable
  },
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    columns: {
      type: Array,
      default: () => ([])
    },
    page: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    totalRecords: {
      type: Number,
      default: 0
    },
    tableProps: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pagedTableProps () {
      const defaults = {
        itemsPerPageSelect: {
          label: 'Items per page:',
          values: [10, 25, 50, 100, 500, 1000]
        },
        itemsPerPage: this.pageSize,
        columnFilter: false,
        tableFilter: false,
        sorter: false
      }
      return merge(defaults, this.tableProps)
    },
    totalPages () {
      // Make sure CPagination always shows at least one page
      return Math.ceil(this.totalRecords / this.pageSize) || 1
    },
    startIndex () {
      return Math.min((this.page - 1) * this.pageSize + 1, this.totalRecords)
    },
    endIndex () {
      return Math.min(this.page * this.pageSize, this.totalRecords)
    }
  },
  methods: {
    /**
     * onChange event handler for user changing the page
     * using the pagination controls.
     *
     * @param {Number} selectedPage
     */
    handlePageChange (selectedPage) {
      this.$emit('page-change', {
        page: selectedPage,
        pageSize: this.pageSize
      })
    },
    /**
     * onChange event handler for itemsPerPageSize select
     * value is changed.
     */
    handlePageSizeChange (pageSize) {
      this.$emit('page-change', {
        page: 1,
        pageSize: pageSize
      })
    }
  }
}
</script>
