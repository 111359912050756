<template>
  <div class="table-filter-options py-3">
    <CRow v-if="showHeader" class="header">
      <CCol>
        <h5 class="pl-2 pb-1">
          Filter By:
        </h5>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="auto" class="filter-options">
        <RButtonGroup
          v-for="(toggles, togglesField) in toggleGroups"
          :key="toggles.label"
          v-model="selections[togglesField]"
          :options="toggles.options"
          class="mx-2"
        >
          {{ toggles.label }}
        </RButtonGroup>
        <RDropdownMultiSelect
          v-for="(select, selectField) in multiselects"
          :key="select.label"
          v-model="selections[selectField]"
          :options="select.options"
          class="mx-2"
        >
          {{ select.label }}
        </RDropdownMultiSelect>
        <RInputFilter
          v-for="(input, inputField) in inputs"
          :key="input.label"
          v-model="selections[inputField]"
          :placeholder="input.label"
          class="mx-2"
        />
        <RButton
          v-if="activeFilters"
          variant="link"
          class="ml-2"
          @click="handleClearAll"
        >
          Clear filters ({{ activeFilters }})
        </RButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
/**
 * Wrapper for custom Rooof filter components. Takes in configuration
 * object specifying each field that requires a filter, the type of filter,
 * and other information needed to render each one.
 *
 * Example:
 * {
 *   status: {
 *     type: 'toggles',
 *     options: [
 *       { label: 'Active', value: 'active' },
 *       { label: 'Inactive', value: 'inactive' },
 *       { label: 'Trial', value: 'trial' }
 *   },
 *   propertyName: {
 *     type: 'input',
 *     label: 'Property Name'
 *   },
 *   city: {
 *     type: 'multiselect',
 *     label: 'City',
 *     options: [
 *       { label: 'Hollywood', value: 'hollywood' },
 *       { label: 'Seattle', value: 'seattle' }
 *   }
 * }
 *
 * Events:
 *
 * @change: triggered when there is a change in any of the filter selections
 */
import { RButton, RButtonGroup } from '@/components/buttons'
import { RDropdownMultiSelect, RInputFilter } from '@/components/inputs'

const FILTER_TYPES = {
  INPUT: 'input',
  TOGGLES: 'toggles',
  MULTISELECT: 'multiselect'
}

export default {
  name: 'RTableFilterOptions',
  components: {
    RButton,
    RButtonGroup,
    RInputFilter,
    RDropdownMultiSelect
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
      validator: values => {
        for (const value of Object.values(values)) {
          if (!value.type || !Object.values(FILTER_TYPES).includes(value.type)) {
            return false
          }
          if (value.type !== FILTER_TYPES.INPUT) {
            for (const option of value.options) {
              if (!option.hasOwnProperty('label') || !option.hasOwnProperty('value')) {
                return false
              }
            }
          }
        }
        return true
      }
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const selections = {}
    for (const key of Object.keys(this.config)) {
      selections[key] = this.config[key].type === FILTER_TYPES.INPUT ? '' : []
    }
    return { selections }
  },
  computed: {
    toggleGroups () {
      return this.getFieldsWithType(FILTER_TYPES.TOGGLES)
    },
    inputs () {
      return this.getFieldsWithType(FILTER_TYPES.INPUT)
    },
    multiselects () {
      return this.getFieldsWithType(FILTER_TYPES.MULTISELECT)
    },
    activeFilters () {
      let activeFilters = 0
      for (const filter of Object.values(this.selections)) {
        if (filter.length > 0) {
          activeFilters++
        }
      }
      return activeFilters
    }
  },
  watch: {
    selections: {
      handler: function (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  methods: {
    /**
     * Helper function to extract the fields with a particular type from config
     */
    getFieldsWithType (type) {
      return Object.entries(this.config).reduce((acc, [key, value]) => {
        if (value.type === type) {
          acc[key] = value
        }
        return acc
      }, {})
    },
    /**
     * onClick handler for 'Clear filters' button to clear input and select filters
     */
    handleClearAll () {
      for (const key in this.toggleGroups) {
        this.selections[key] = []
      }
      for (const key in this.multiselects) {
        this.selections[key] = []
      }
      for (const key in this.inputs) {
        this.selections[key] = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-filter-options {
  .header {
    color: $gray-800;
  }
  .filter-options {
    display: flex;
  }
}
</style>
