<template>
  <div class="r-data-table">
    <CDataTable
      v-bind="dataTableProps"
      :items="tableData"
      :fields="tableColumns"
      hover
      @pages-change="$emit('pages-change', $event)"
      @pagination-change="$emit('pagination-change', $event)"
      @page-change="$emit('page-change', $event)"
      @row-clicked="$emit('row-clicked', $event)"
      @update:sorter-value="$emit('update:sorter-value', $event)"
      @update:table-filter-value="$emit('update:table-filter-value', $event)"
      @update:column-filter-value="$emit('update:column-filter-value', $event)"
      @filtered-items-change="$emit('filtered-items-change', $event)"
    >
      <!-- render select column -->
      <template v-if="select" #_select-header>
        <CInputCheckbox
          custom
          @update:checked="_handleSelectAll($event)"
        />
      </template>
      <template v-if="select" #_select="{item}">
        <td class="text-center">
          <CInputCheckbox
            custom
            :checked="item._selected"
            @update:checked="() => _handleSelect(item)"
          />
        </td>
      </template>
      <!-- render table columns -->
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
      <template #no-items-view>
        <div class="no-data">
          No data available
        </div>
      </template>
    </CDataTable>
  </div>
</template>

<script>
/**
 * RDataTable
 *
 * Wrapper for CDataTable with custom styles.
 * Docs: https://coreui.io/vue/docs/components/table.html
 *
 * If the `select` prop is true, the table will render a checkbox
 * as the first column of every row. Listen to @selection-change
 * to get the selected rows, and use a ref to access the "public"
 * methods which programatically control row selection.
 *
 * Events:
 *
 * @selection-chage: emitted when a user selects/unselects a row.
 * (+ all CoreUI table events)
 */
import merge from 'lodash.merge'

import { deepCopy } from '@/utils'

export default {
  name: 'RDataTable',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    select: {
      type: Boolean,
      default: false
    },
    tableProps: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    dataTableProps () {
      const defaults = {
        columnFilter: false,
        tableFilter: false,
        sorter: {
          external: false,
          resetable: true
        }
      }
      return merge(defaults, this.tableProps)
    },
    tableColumns () {
      if (this.select) {
        return [
          { key: '_select', label: '', _style: 'width: 1%; padding-right: 5px;' },
          ...this.fields
        ]
      }
      return this.fields
    },
    tableData () {
      // Be warned, this will affect row values referenced in scoped slots
      return deepCopy(this.items).map(row => {
        for (const key in row) {
          if (row[key] === null || row[key] === undefined) {
            row[key] = ''
          }
        }
        return row
      })
    }
  },
  methods: {
    /**
     * Handle row checkbox `update` event.
     *
     * @param {Object} row
     */
    _handleSelect (row) {
      this._toggleRowSelection(row, !row._selected)
    },
    /**
     * Handle the header row checkbox `update` event.
     */
    _handleSelectAll () {
      const selected = this.tableData.filter(row => row._selected)
      if (selected.length === this.tableData.length) {
        this.clearSelection()
      } else {
        this.selectAll()
      }
    },
    /**
     * Select/unselect the given row.
     *
     * @param {Object} row
     * @param {Boolean} selected
     */
    _toggleRowSelection (row, selected) {
      this.$set(row, '_selected', selected)
      this.$set(row, '_classes', selected ? 'table-selected' : 'table-unselected')
      this.$emit('selection-change', this.tableData.filter(row => row._selected))
    },
    /**
     * Select the row at `index`.
     *
     * Parent component can access via refs.
     *
     * @param {Number} index
     */
    selectRow (index) {
      const row = this.tableData[index]
      this._toggleRowSelection(row, true)
    },
    /**
     * Select all rows.
     *
     * Parent component can access via refs.
     */
    selectAll () {
      for (const row of this.tableData) {
        this._toggleRowSelection(row, true)
      }
    },
    /**
     * Clear all selected rows.
     *
     * Parent component can access via refs.
     */
    clearSelection () {
      for (const row of this.tableData) {
        this._toggleRowSelection(row, false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .table {
  border-collapse: separate;
  border-spacing: 0rem $table-margin-y;

  .no-data {
    text-align: center;
    font-size: 1.3em;
    color: $gray-400;
  }

  // Override default selected table row style
  .table-selected td {
    background-color: $gray-100;
  }

  thead, thead tr, thead th, tbody, tbody tr {
    border: none;
  }

  // left-align column sort indicator
  th  {
    div:first-child {
      display: inline-block;
      padding-right: 5px;
    }
    svg {
      right: initial;
    }
  }

  tbody td {
    border-top: 1px solid $gray-100;
    border-bottom: 1px solid $gray-100;
    vertical-align: middle;
    padding: 0.5rem;

    &:first-child {
      border-left: 1px solid $gray-100;
      border-radius: 5px 0px 0px 5px;
    }

    &:last-child {
      border-right: 1px solid $gray-100;
      border-radius: 0px 5px 5px 0px;
    }

    a {
      font-weight: 600;
      color: $info;
    }
  }
}
</style>
